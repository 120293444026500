import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Why from "../components/why"
import Cta from "../components/cta"

const About = () => (
  <Layout>
    <Seo title="About Us" />
    <section className="about">
      <div className="container">
        <div className="grid">
          <div className="column-xs-12">
            <h4>Experienced and Trusted Painting Company</h4>
            <h2>Luis's Painting is a locally owned, licensed, and full service painting company with more than 20 years of experience serving New Jersey, New York, and Indiana areas.</h2>
            <p>Today, Luis's Painting is one of the most long-running painting companies in the area. We are proud of the many years of experience we have in the painting industry. Luis's Painting continues to provide high quality services at reasonable rates. Luis’s Painting promises to offer you with quick, dependable, and skilled services.</p>
            <p>When hiring us, you can be assured that we will always do the right thing on every job. We are serious and hard-working painters that truly give full attention to each and every detail on every project. We work closely with every client and assure professional, superior painting services. We use only the best, high quality products for the results that will meet or surpass expectations. We want us to be your choice for painting and handyman services.</p>
            <p>We guarantee high quality work on every job that we perform. We at Luis's Painting proudly serve all over the surrounding areas of New Jersey, New York, and Indiana for over 20 years and still going at it!</p>
            <p>&mdash; Luis,<br />Owner of Luis's Painting</p>
          </div>
        </div>
      </div>
    </section>
    <Why />
    <Cta />
  </Layout>
)

export default About
